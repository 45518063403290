$(document).ready(function () {

    $('.clients-slider').slick({
        infinite: true,
        slidesToShow: 5,
        responsive:[
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 668,
                settings: "unslick"
            }
        ]
    });

    $(".page-scroll").mPageScroll2id();

    $('.product-slider-block').slick({
        infinite: true,
        slidesToShow: 1,
        dots: true
    });

    $('.slider-for-block').slick({
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav-block'
    });

    $('.slider-nav-block').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-for-block',
        focusOnSelect: true
        // responsive:[
        //     {
        //         breakpoint: 665,
        //         settings: {
        //             slidesToShow: 2
        //         }
        //     }
            // {
            //     breakpoint: 668,
            //     settings: "unslick"
            // }
        // ]
    });

    $('.burger').click(function(){
        $('.main-header').toggleClass('open-burger');
    });
});


$(window).load(function() {

    for(var i = 1; i <= 100; i++) {
        (function(index) {
            setTimeout(function() { 
                // $('.counter').text(index + '%'); 
                $('.loader-line').css('background-size', index + '%' + ' 100% ');
            }, 10*i);
        })(i);
    }


    // $(".loader_inner").delay(2000).fadeOut();
    $(".loader").delay(1000).fadeOut("slow");
    $(".rect1").delay(1200).slideDown("slow");
    $(".rect2").delay(1300).slideDown("slow");
    $(".rect3").delay(1400).slideDown("slow");

    // $("header.index-page").delay(1000).animated("fade_in_down_header");
    // $(".main-slide").delay(2000).animated("fade_in_anim");
    // $("section.slider-prod-wrap").addClass("move_up_margin");
    // $(".repair-sheet").delay(2000).animated("fade_in_anim");
    // $(".repair-cost").delay(2000).animated("fade_in_right_anim_delay");
    $(".main-h2, .animation1, h3, p, a, figure, .full-article-block img, span:not(.loader-line, .img-text, .untransformed, .burg-line), .main-footer, input, .form-block, .team-member").animated('fadein_moveup_60');
    $(".bgline1").animated('height-anim');
    $(".bgline2").animated('height-anim');
    $(".bgline3").animated('height-anim');

}); 

    (function($) {
        $.fn.animated = function(inEffect) {
                $(this).css("opacity", "0").addClass("animated").waypoint(function() {
                                $(this).addClass(inEffect).css("opacity", "1");

                }, {
                        offset: "99%"
                });
        };
    })(jQuery);